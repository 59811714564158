import AMapLoader from '@amap/amap-jsapi-loader'
import { showToast } from '@/hooks/useVant'
import { push, isFromHd, goToVote, isHd } from '@/utils'
import { store } from '@/pinia/store'
import { req } from '@/api'
let map
const mapKey = '1a3782a68749563c2ddf3862c5da3c47' // 申请好的Web端开发者Key，首次调用 load 时必填

const localAddress = isHd() ? {
  position: {
    lng: '116.4625',
    lat: '39.8702',
  },
  cityName: '北京市',
  adcode: '110100',
  longitude: '116.4625',
  latitude: '39.8702',
  cityCode: '110100',
  locationAddress: '首都图书馆',
  regeocode: {
    formattedAddress: '北京市朝阳区南磨房乡首都图书馆',
    pois: [],
  },
} : {
  position: {
    lng: '120.301663',
    lat: '31.574729',
  },
  cityName: '无锡市',
  adcode: '320200',
  longitude: '120.301663',
  latitude: '31.574729',
  cityCode: '320200',
  locationAddress: '秦邦宪故居',
  regeocode: {
    formattedAddress: '江苏省无锡市梁溪区崇安寺街道无锡市名人故居文物管理处秦邦宪故居',
    pois: [],
  },
}

// 根据经纬度获取位置信息
const initAMap = (type = 0, params, arr) => {
  return new Promise((resolve) => {
    AMapLoader.load({
      key: mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
      plugins: ['AMap.Geolocation', 'AMap.Geocoder', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Driving', 'AMap.Polyline'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    })
      .then(async (AMap) => {
        let result = null //以下各个方法的返回值，如果以下用Promise包了，那就有返回值，反之就是正常函数，返回undefined
        switch (type) {
          case 1:
            result = await mapInit(AMap, params)
            break
          case 2:
            result = await getGeocoder(AMap, params)
            break
          case 3:
            result = await getLocation(AMap)
            break
          case 4:
            result = await getLocationByWx(params).catch(err => {
              console.error(err, 'getLocationByWx')
              return Promise.resolve(localAddress)
            })
            break
          case 5:
            result = await setMarker(AMap, params).catch(err => {
              console.error(err, 'getLocationByWx')
              return Promise.resolve(localAddress)
            })
            break
          case 6:
            result = await labelsDataInit(AMap, arr).catch(err => {
              console.error(err, 'getLocationByWx')
              return Promise.resolve(localAddress)
            })
            break
          case 7:
            result = await searchPlace(AMap, params).catch(err => {
              console.error(err, 'getLocationByWx')
              return Promise.resolve(localAddress)
            })
            break
          case 8:
            result = await drivingMap(AMap, params).catch(err => {
              console.error(err, 'getLocationByWx')
              return Promise.resolve(localAddress)
            })
            break
          case 9:
            result = await searchPlaceByCityName(AMap, params).catch(err => {
              console.error(err, 'getLocationByWx')
              return Promise.resolve(localAddress)
            })
            break
          case 10:
            result = await searchNearPlace(AMap, params).catch(err => {
              console.error(err, 'getLocationByWx')
              return Promise.resolve(localAddress)
            })
            break
          default:
            result = localAddress
            break
        }
        resolve(result) //把结果暴露返回出去！！！
      })
      .catch((e) => {
        console.log(e)
        resolve(localAddress)
      })
  })
}

//type=1
const mapInit = (AMap, points) => {
  return new Promise((resolve) => {
    map = new AMap.Map('container', {
      resizeEnable: true,
      center: [points.lng, points.lat],
      zoom: 13.8,
      scrollWheel: true,
    })
    console.log(map)
    map.clearMap()
    setMarker(AMap, points)
    map.panTo(points)
    //isClick:默认false
    if (!points.isClick) {
      map.on('click', clickMap(AMap))
      map.on('moveend', movedMap(AMap))
    }
    resolve(map)
  }).catch(err => {
    console.error(err, 'mapinit.error')
    return Promise.resolve(localAddress)
  })
}
//点击地图
const clickMap = (AMap) => {
  map.on('click', (ev) => {
    map.clearMap()
    setMarker(AMap, { lng: ev.lnglat.lng, lat: ev.lnglat.lat })
    map.panTo([ev.lnglat.lng, ev.lnglat.lat])
    getGeocoder(AMap, { lng: ev.lnglat.lng, lat: ev.lnglat.lat })
  })
}
// 拖动地图
const movedMap = (AMap) => {
  map.on('moveend', () => {
    console.log("地图中心点已经改变");
    // 获取最新的地图中心点信息
    const center = map.getCenter();
    console.log(`新的地图中心点经度：${center.lng}`);
    console.log(`新的地图中心点纬度：${center.lat}`);
    map.clearMap()
    setMarker(AMap, { lng: center.lng, lat: center.lat })
    map.panTo([center.lng, center.lat])
    getGeocoder(AMap, { lng: center.lng, lat: center.lat })
  })
}
//type=2
const getGeocoder = (AMap, points) => {
  return new Promise((resolve, reject) => {
    const storeData = store()
    storeData.isShowLoading = false
    AMap.plugin('AMap.Geocoder', () => {
      var geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: 'all',
      })
      var lnglat = [points.lng, points.lat]
      geocoder.getAddress(lnglat, async (status, result) => {
        console.log('getAddress', status, result)
        if (status === 'complete' && result.info === 'OK') {
          console.log('getAddress', status, result)
          console.log('getAddress66', result?.regeocode?.addressComponent?.city)
          // result为对应的地理位置详细信息
          const res = await req.getCodeByAdcode({
            adcode: points.adcode ? points.adcode : result?.regeocode?.addressComponent?.adcode
          })
          if (res.data.enabled === 1) {
            storeData.unOpenCityData.cityAreaCode = ''
            storeData.unOpenCityData.lat = ''
            storeData.unOpenCityData.lng = ''
          } else {
            storeData.unOpenCityData.cityAreaCode = result.regeocode.addressComponent.citycode
            storeData.unOpenCityData.lat = points.lat
            storeData.unOpenCityData.lng = points.lng
            goToVote()
          }
          const localAddress = {
            position: {
              lng: points.lng,
              lat: points.lat,
            },
            adcode: res.data.code,
            longitude: points.lng,
            latitude: points.lat,
            cityName: points.cityName ? points.cityName : (result?.regeocode?.addressComponent?.city.length ? result?.regeocode?.addressComponent?.city : result?.regeocode?.addressComponent?.province),
            cityCode: res.data.code,
            locationAddress: result.regeocode.formattedAddress,
            regeocode: result.regeocode,
          }
          storeData.mapChangeTimeStamp = new Date().getTime()
          storeData.mapChangeLoc = {
            cityCode: res.data.code,
            cityName: localAddress.cityName,
            longitude: points.lng,
            latitude: points.lat
          }
          if (result?.regeocode?.pois) {
            storeData.nowNearAddress = result?.regeocode?.pois.map(x => {
              return {
                ...x,
                cityCode: localAddress.cityCode,
                cityName: localAddress.cityName
              }
            })
          }
          resolve(localAddress)
        } else {
          showToast('定位失败')
          storeData.unOpenCityData.cityAreaCode = ''
          storeData.unOpenCityData.lat = ''
          storeData.unOpenCityData.lng = ''
          storeData.mapChangeTimeStamp = new Date().getTime()
          storeData.mapChangeLoc = {
            cityCode: '320200',
            longitude: points.lng,
            latitude: points.lat
          }
          resolve(localAddress)
          storeData.isShowLoading = false
        }
      })
    })
  }).catch(err => {
    console.error('getGeocoder', err)
    return Promise.resolve(localAddress)
  })
}
//type=3
const getLocation = (AMap) => {
  return new Promise((resolve, reject) => {
    AMap.plugin('AMap.Geolocation', async () => {
      const storeData = store()
      storeData.isShowLoading = true
      const geolocation = await new AMap.Geolocation({
        // 是否使用高精度定位，默认：true
        enableHighAccuracy: true,
        // 设置定位超时时间，默认：无穷大
        timeout: 10000,
        // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
        buttonOffset: new AMap.Pixel(10, 20),
        //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        zoomToAccuracy: true,
        //  定位按钮的排放位置,  RB表示右下
        buttonPosition: 'RB',
        // getCityWhenFail: true
      })
      geolocation?.getCurrentPosition(async (status, result) => {
        if (status == 'complete' && result.info === 'SUCCESS') {
          console.log('getCurrentPosition', status, result)
          resolve(getGeocoder(AMap, result.position))
        } else {
          geolocation.getCityInfo(async (status, result) => {
            console.log('getCityInfo', status, result)
            if (status === 'complete') {
              resolve(
                getGeocoder(AMap, {
                  lng: result.position[0],
                  lat: result.position[1],
                  cityName: result.city,
                })
              )
            } else {
              showToast('定位失败')
              const result = {
                position: isHd() ? {
                  lng: '116.4625',
                  lat: '39.8702',
                  cityName: '北京市',
                  cityCode: '110100',
                } : {
                  lng: '120.301663',
                  lat: '31.574729',
                  cityName: '无锡市',
                  cityCode: '320200',
                },
              }
              resolve(getGeocoder(AMap, result.position))
            }
          })
          storeData.isShowLoading = false
        }
      })
    })
  }).catch(err => {
    console.error(err, 'getLocation')
    return Promise.resolve(localAddress)
  })
}
//type=4 wxsdk获取当前位置经纬度
const getLocationByWx = async () => {
  wx?.ready(() => {
    wx?.getLocation({
      type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: (res) => {
        initAMap(4, res)
      },
      fail: (error) => {
        showToast('定位失败，请稍后重试...')
      },
      cancel: (error) => {
        showToast('定位未授权，请稍后重试...')
      },
    })
  })
}
//type=5
const setMarker = (AMap, points) => {
  console.log('画锚点', points)
  let marker = new AMap.Marker({
    position: [points.lng, points.lat],
    icon: new AMap.Icon({
      // 图标尺寸
      size: new AMap.Size(32, 32),
      // 图标的取图地址
      image: points.image ? points.image : 'https://oss.wangyuedaojia.com/upload/20221102/c907dc464e9e49cc9c0b19e2df2c617b.png',
      // 图标所用图片大小
      imageSize: new AMap.Size(32, 32),
    }),
    anchor: 'center', //设置锚点
    offset: new AMap.Pixel(0, 0), //设置偏移量
    label: {
      direction: 'top',
      content: points.text ? points.text : '',
      offset: new AMap.Pixel(0, -5),
    },
  })
  map.add(marker)
}
//type 6  设置多个remark
const multipleMarkers = (AMap, arr) => {
  let markers = []
  for (var i = 0; i < arr.length; i++) {
    var curData = arr[i]
    curData.extData = {
      index: i,
    }
    var labelMarker = new AMap.Marker(curData)
    labelMarker.on('click', showInfo)
    markers.push(labelMarker)
  }

  map.add(markers)
  // map.panTo(markers[0]._position)
}
//type=7
const searchPlace = (AMap, params) => {
  return new Promise((resolve, reject) => {
    const storeData = store()
    AMap.plugin('AMap.PlaceSearch', async () => {
      let placeSearch = new AMap.PlaceSearch({
        city: params.cityCode, // 趣味点城市，反对城市名、citycode、adcode
        citylimit: true, // 是否强制在设置的城市内搜寻，默认false
        pageSize: 50, // 每页条数，默认10，范畴1-50
        pageIndex: 1, // 页码
        extensions: 'all', // 默认base，返回根本地址信息；all：返回详细信息
      })
      if (Number(params.type) === 0) {
        console.log('查看附近地址')
        console.log('params参数', params)
        // 查看附近地址
        placeSearch.searchNearBy('', [params.lng, params.lat], 2000, function (status, result) {
          console.log(status)
          console.log(result)
          if (status == 'complete') {
            console.log('地图返回数据')
            console.log(result)
            resolve(result?.poiList?.pois || [])
          } else {
            resolve([])
          }
        })
      } else {
        // 搜索地址
        const res = await getCoderMapLocation(AMap, params.cityName + params.keyWord)
        // 查看附近地址
        placeSearch.searchNearBy(params.keyWord, res.location, 50000, function (status, result) {
          if (status == 'complete') {
            console.log('地图返回数据')
            console.log(result)
            resolve(result?.poiList?.pois)
          } else {
            resolve([])
          }
        })
      }
    })
    // AMap.plugin('AMap.PlaceSearch', async () => {
    //   var placeSearch = await new AMap.PlaceSearch({
    //     city: params.cityCode,
    //     pageSize: 50,
    //   })
    //   placeSearch.search(params.cityName + params.keyWord, (status, result) => {
    //     console.log(status)
    //     console.log(result)
    //     // 查询成功时，result即对应匹配的POI信息
    //     console.log('searchPlace,7', params, result)
    //     if (status === 'complete') {
    //       resolve(result) //resolve出去
    //     } else {
    //       // 异常情况，获取不到附近地址，此时也不要reject，因为reject会阻塞下面的代码执行，返回个空数组即可
    //       resolve([])
    //     }
    //   })
    // })
    // const placeSearch = new AMap.PlaceSearch({
    //   // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
    //   city: params.cityCode,
    //   pageSize: 50,
    // })

    // storeData.locationData.nearAddressList = nearAddressList
  })
}

const getCoderMapLocation = (AMap, address) => {

  return new Promise((resolve, reject) => {
    AMap.plugin('AMap.Geocoder', async () => {
      const coder = await new AMap.Geocoder()
      coder.getLocation(address, async (status, result) => {
        if (status === 'complete' && result.geocodes.length) {
          resolve(result.geocodes[0])
        } else {
          reject(false)
          console.error('根据地址查询位置失败');
        }
      })
    })
  })
}

//type=9
const searchPlaceByCityName = (AMap, params) => {
  return new Promise((resolve, reject) => {
    const storeData = store()
    AMap.plugin('AMap.PlaceSearch', async () => {
      let placeSearch = new AMap.PlaceSearch({
        city: params.cityName, // 趣味点城市，反对城市名、citycode、adcode
        type: params.type, // 兴趣点类别，多个类别用“|”分割，如“餐饮|酒店|电影院”
        // city: params.cityCode, // 趣味点城市，反对城市名、citycode、adcode
        citylimit: true, // 是否强制在设置的城市内搜寻，默认false
        pageSize: 20, // 每页条数，默认10，范畴1-50
        pageIndex: 1, // 页码
        extensions: 'all', // 默认base，返回根本地址信息；all：返回详细信息
      })
      // 搜索地址
      placeSearch.search(params?.keyWord || params.cityName, function (status, result) {
        console.log(result)
        if (status == 'complete') {
          console.log('地图返回数据***********')
          let arr = result?.poiList?.pois
          for (let i = 0; i < arr.length; i++) {
            if (!arr[i].distance && arr[i].distance !== 0) {
              const d = AMap.GeometryUtil.distance([params.lng || storeData.locationData.longitude, params.lat || storeData.locationData.latitude], arr[i].location)
              arr[i].distance = Math.round(d)
            }
          }
          resolve(arr || [])
        } else {
          resolve([])
        }
      })
    })
  })
}

//type=10
const searchNearPlace = (AMap, params) => {
  return new Promise((resolve, reject) => {
    const storeData = store()
    AMap.plugin('AMap.PlaceSearch', async () => {
      let placeSearch = new AMap.PlaceSearch({
        city: params.cityName, // 兴趣点城市，可选值：城市名（中文或中文全拼）、citycode、adcode
        type: params.type, // 兴趣点类别，多个类别用“|”分割，如“餐饮|酒店|电影院”
        citylimit: true, // 是否强制在设置的城市内搜寻，默认false
        pageSize: 20, // 每页条数，默认10，范畴1-50
        pageIndex: 1, // 页码
        extensions: 'all', // 默认base，返回根本地址信息；all：返回详细信息
      })
      console.log('params******', params)
      // 搜索地址
      placeSearch.searchNearBy(params.keyWord, [params.lng, params.lat], 50000, function (status, result) {
        if (status == 'complete') {
          console.log('地图返回数据')
          console.log(result)
          resolve(result?.poiList?.pois)
        } else {
          resolve([])
        }
      })
    })
  })
}

const showInfo = (e) => {
  if (isFromHd()) {
    push('/projectModule/technicianDetail/index', { tecId: e.target._opts.id })
  } else {
    push('/technicianModule/technicianDetail/index', { techId: e.target._opts.id })
  }
}
//技师地图头像marker
const labelsDataInit = (AMap, arr) => {
  let labelsData = []
  if (!!arr) {
    arr.forEach((x) => {
      labelsData.push({
        id: x.id,
        position: [x.lbs[0], x.lbs[1]],
        icon: x.avator,
        anchor: 'center', //设置锚点
        offset: new AMap.Pixel(0, 0), //设置偏移量
        label: {
          direction: 'top',
          content: x.lineState == '101003' ? "<span style='color:#ff0000'>忙碌中</span>" : x.name,
          offset: new AMap.Pixel(0, -5),
        },
      })
    })
    setTimeout(() => {
      multipleMarkers(AMap, labelsData)
    }, 300)
  }
}

//type ==8  绘制路线
const drivingMap = async (AMap, params) => {
  map = await new AMap.Map('container', {
    resizeEnable: true,
    center: [params.origin.lng, params.origin.lat],
    zoom: 14,
  })
  return new Promise((resolve, reject) => {
    AMap.plugin('AMap.Driving', function () {
      var driving = new AMap.Driving({
        // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
        map,
        policy: AMap.DrivingPolicy.LEAST_TIME,
      })

      var startLngLat = [params.origin.lng, params.origin.lat]
      var endLngLat = [params.destination.lng, params.destination.lat]

      driving.search(startLngLat, endLngLat, function (status, result) {
        // 未出错时，result即是对应的路线规划方案
        if (status === 'complete') {
          if (result.routes && result.routes.length) {
            // 绘制第一条路线，也可以按需求绘制其它几条路线
            resolve(result.routes[0]) //resolve出去
          }
        } else {
        }
      })
    })
  })
}
//绘制路线
const drawRoute = (AMap, route) => {
  var path = parseRouteToPath(route)

  var startMarker = new AMap.Marker({
    position: path[0],
    icon: 'https://webapi.amap.com/theme/v1.3/markers/n/start.png',
    map: map,
  })

  var endMarker = new AMap.Marker({
    position: path[path.length - 1],
    icon: 'https://webapi.amap.com/theme/v1.3/markers/n/end.png',
    map: map,
  })

  var routeLine = new AMap.Polyline({
    path: path,
    isOutline: true,
    outlineColor: '#ffeeee',
    borderWeight: 2,
    strokeWeight: 5,
    strokeColor: '#0bb880',
    lineJoin: 'round',
  })

  routeLine.setMap(map)

  // 调整视野达到最佳显示区域
  map.setFitView([startMarker, endMarker, routeLine])
}
// 解析DrivingRoute对象，构造成AMap.Polyline的path参数需要的格式
const parseRouteToPath = (route) => {
  var path = []

  for (var i = 0, l = route.steps.length; i < l; i++) {
    var step = route.steps[i]

    for (var j = 0, n = step.path.length; j < n; j++) {
      path.push(step.path[j])
    }
  }

  return path
}
export { getLocationByWx, initAMap, mapKey, localAddress as defaultAddress }
