export const WY_APPKEY = {
  PROD: '66629618cac2a664de4501c2',
  TEST: '6667ffb5cac2a664de472edc',
}

export const HD_APPKEY = {
  PROD: '66629c51940d5a4c49673ea7',
  TEST: '66680019940d5a4c4969645f',
}

export const OTHERS_APPKEY = {
  PROD: '6662a012940d5a4c49673fa8',
  TEST: '6668003a940d5a4c496964d0',
}

export const PKG_LIST = {
  ANDROID:
    'com.st.QSB,com.st.home,com.st.main,com.st.mine,com.st.order,com.st.technician,com.st.trend,com.st.publiclib',
  IOS: 'com.st.qsb',
}

export const IGNORE_ERROR = [
  'unhandledrejection',
  'Script error',
  'Network Error',
  'Failed to fetch',
  'Load failed',
  'Request aborted',
  'timeout of 0ms exceeded',
  'Unable to preload CSS',
  'The operation was aborted',
  'The operation is not supported',
  'Importing a module script failed',
  'The play() request was interrupted',
  'play() can only be initiated by a user gesture',
  'The element has no supported sources',
  'Failed to start the audio device',
  'The callback prior to sending a one-to-one chat message was triggered, and the App backend returned a response to forbid the message!',
  '已取消',
  '请求超时',
  '请求参数错误',
  '请求过于频繁，请稍后再试',
]

export const ORDER_PROCESS_PAGE = {
  HOMEMODULE: '首页-推荐套餐',
  TECHNICIANLIST: '技师列表',
  TECHNICIANLISTMAP: '地图模式',
  PRODUCTLIST: '套餐列表',
  TECHNICIANDETAIL: '技师详情',
  PRODUCTDETAIL: '套餐详情',
  CHOOSETECHNICIAN: '选择技师',
  BOOKINGORDER: '预约下单',
  MYORDERS: '订单列表',
  ORDERDETAIL: '订单详情',
  MYMODULEHOMEPAGE: '我的页面',
  MYMODULEHOME: '我的-精选套餐',
  MYCOUPON: '我的优惠券',
  MYATTENTION: '我的关注',
  FOOTPRINT: '我的足迹',
  MYEVALUATION: '我的评价',
  TRENDSDETAIL: '作品详情',
  IMMESSAGE: '私聊',
  DAILYSPECIAL: '超级满减',
  GROWTHCENTER: '成长中心',
  MYGROWTH: '我的成长值'
}
