import axios from 'axios'
import CryptoJSCore from 'crypto-js/core'
import { baseUrl } from '@/config/index.js'
import { isWhiteListInit } from '@/hooks/useApiWhiteList'
import { store } from '@/pinia/store'

let myConfig = {
  baseURL: baseUrl,
}

// 正在进行中的请求队列
let reqSet = new Set()

// 序列化参数
const serializeAndSort = (obj) => {
  const sortedKeys = Object.keys(obj).sort();
  const pairs = [];

  sortedKeys.forEach(key => {
    if (obj[key] instanceof Object) {
      pairs.push(`"${key}":${serializeAndSort(obj[key])}`);
    } else {
      pairs.push(`"${key}":"${obj[key]}"`);
    }
  });

  return '{' + pairs.join(',') + '}';
}

// 生成MD5加密key
const createMD5Key = (config) => {
  const params = config?.params || {}
  const data = config?.data ? (Object.prototype.toString.call(config?.data) === "[object Object]" ? config.data : JSON.parse(config.data)) : {}
  const payload = { ...params, ...data }
  const key = serializeAndSort(payload)
  return CryptoJSCore.MD5(config.url + key).toString()
}

// 拦截函数
const stopRepeatRequest = (reqSet, config, cancel, errorMessage) => {
  // reqSet - 请求缓存列表
  // config - 当前请求config数据
  // cancel - 请求中断函数
  // errorMessage - 请求拦截时需要显示的错误信息
  const errorMsg = errorMessage || ''

  // isWhiteListInit校验当前请求接口是否在白名单里
  const md5Key = createMD5Key(config)
  if (isWhiteListInit(config.url) && reqSet.has(md5Key)) {
    cancel(errorMsg)
    return
  }
  reqSet.add(md5Key)
}

// 清除记录
const allowRequest = (reqSet, config) => {
  // reqSet - 请求缓存列表
  // config - 当前请求config数据
  const md5Key = createMD5Key(config)
  if (reqSet.has(md5Key)) {
    reqSet.delete(md5Key)
  }
}

const _axios = axios.create(myConfig)
// _axios.defaults.headers.common['X-token'] = token
_axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
_axios.interceptors.request.use(
  function (config) {
    const storeData = store()
    // Do something before request is sent
    let cancel
    // 设置cancelToken对象
    config.cancelToken = new axios.CancelToken(function(c) {
      cancel = c
    })
    // 阻止重复请求。当上个请求未完成时，相同的请求不会进行
    stopRepeatRequest(reqSet, config, cancel, '请求过于频繁，请稍后再试')

    config.withCredentials = true
    const obj = {}
    if (config.data) {
      for (const key in config.data) {
        if (config.data[key] != null && config.data[key] != undefined) {
          obj[key] = config.data[key]
        }
      }
    }
    config.data = obj
    // if (config.url == 'v6/order/customer/personas') {
    //   config.baseURL = "https://i.wangyuedaojia.com/api/"
    // }
    if (storeData.headerInfo['c-id']) {
      config.headers['c-id'] = storeData.headerInfo['c-id']
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    // 释放队列里的记录
    allowRequest(reqSet, response.config)

    return response
  },
  function (error) {
    // Do something with response error
    if (!axios.isCancel(error)) {
      // 释放队列里的记录
      allowRequest(reqSet, error.config)
    } else {
      console.log('请求拦截')
    }

    return Promise.reject(error)
  }
)
export default _axios
