import AMapLoader from '@amap/amap-jsapi-loader'
import { store } from '@/pinia/store'
import { showToast } from '@/hooks/useVant'
let map
const mapKey = '1a3782a68749563c2ddf3862c5da3c47' // 申请好的Web端开发者Key，首次调用 load 时必填
let oldMarker

// 初始化地图
const initAMap = (type = 0, params) => {
  return new Promise((resolve) => {
    AMapLoader.load({
      key: mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
      plugins: ['AMap.Geolocation', 'AMap.Geocoder', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Driving', 'AMap.Polyline', 'AMap.LngLat'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    })
      .then(async (AMap) => {
        let result //以下各个方法的返回值，如果以下用Promise包了，那就有返回值，反之就是正常函数，返回undefined
        switch (type) {
          case 1:
            result = await drivingMap(AMap, params)
            break
          case 2:
            result = await getLocation(AMap)
            break
          case 3:
            result = await getLocationByWx(AMap)
            break
          case 4:
            result = await mapInit(AMap, params)
            break
          default:
            break
        }
        resolve(type === 4 ? AMap : result) //把结果暴露返回出去！！！
      })
      .catch((e) => {
        console.log(e)
      })
  })
}

// 绘制行车路径
const drivingMap = async (AMap, params) => {
  map = await new AMap.Map('container', {
    resizeEnable: true,
    center: [params.origin.lng, params.origin.lat],
    zoom: 14
  })
  return new Promise((resolve) => {
    AMap.plugin('AMap.Driving', function () {
      let driving = new AMap.Driving({
        // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
        map,
        policy: AMap.DrivingPolicy.LEAST_TIME
      })
      let startLngLat = [params.origin.lng, params.origin.lat]
      let endLngLat = [params.destination.lng, params.destination.lat]
      driving.search(startLngLat, endLngLat, function (status, result) {
        // 未出错时，result即是对应的路线规划方案
        if (status === 'complete') {
          if (result.routes && result.routes.length) {
            // 绘制第一条路线，也可以按需求绘制其它几条路线
            resolve(result.routes[0]) //resolve出去
          }
        } else {
        }
      })
    })
  })
}

//type=2
const getLocation = (AMap) => {
  return new Promise((resolve, reject) => {
    AMap.plugin('AMap.Geolocation', async () => {
      const storeData = store()
      storeData.isShowLoading = true
      const geolocation = await new AMap.Geolocation({
        // 是否使用高精度定位，默认：true
        enableHighAccuracy: true,
        // 设置定位超时时间，默认：无穷大
        timeout: 10000,
        // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
        buttonOffset: new AMap.Pixel(10, 20),
        //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        zoomToAccuracy: true,
        //  定位按钮的排放位置,  RB表示右下
        buttonPosition: 'RB',
        // getCityWhenFail: true
      })
      geolocation.getCurrentPosition(async (status, result) => {
        if (status == 'complete' && result.info === 'SUCCESS') {
          console.log('getCurrentPosition', status, result)
          resolve(result.position)
        } else {
          geolocation.getCityInfo(async (status, result) => {
            console.log('getCityInfo', status, result)
            if (status === 'complete') {
              resolve(result.position)
            } else {
              showToast('定位失败')
              resolve([120.301663, 31.574729])
            }
          })
        }
      })
    })
  })
}

//type=3 wxsdk获取当前位置经纬度
const getLocationByWx = async () => {
  wx?.ready(() => {
    wx?.getLocation({
      type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: (res) => {
        initAMap(3, res)
      },
      fail: (error) => {
        showToast('定位失败，请稍后重试...')
      },
      cancel: (error) => {
        showToast('定位未授权，请稍后重试...')
      },
    })
  })
}

//type=4
const mapInit = (AMap, points) => {
  console.log(111)
  console.log(points)
  return new Promise((resolve, reject) => {
    map = new AMap.Map('container', {
      resizeEnable: true,
      center: [points.lng, points.lat],
      zooms: [3, 19],
      zoom: 14
    })
    console.log(map)
    setMarker(AMap, points)
    resolve(map)
  })
}

const setMarker = (AMap, points) => {
  console.log(222)
  console.log(points)
  let marker = new AMap.Marker({
    position: [points.lng, points.lat],
    icon: new AMap.Icon({
      // 图标尺寸
      size: points.type === 1 ? new AMap.Size(26, 38) : new AMap.Size(22, 33),
      // 图标的取图地址
      image: points.image,
      // 图标所用图片大小
      imageSize: points.type === 1 ? new AMap.Size(26, 38) : new AMap.Size(22, 33)
    }),
    anchor: 'center', //设置锚点
    offset: new AMap.Pixel(0, 0) //设置偏移量
  })
  oldMarker = marker
  map.add(marker)
  map.setFitView();
  return marker
}

const multipleMarkers = (AMap, arr, oldMarkers) => {
  console.log(3333)
  console.log(AMap)
  console.log(arr)
  console.log(oldMarker)
  console.log(oldMarkers)
  map.remove(oldMarker)
  map.remove(oldMarkers)
  return new Promise((resolve) => {
    let markers = []
    for (var i = 0; i < arr.length; i++) {
      let marker = new AMap.Marker({
        position: [arr[i].lng, arr[i].lat],
        icon: new AMap.Icon({
          // 图标尺寸
          size: arr[i].type === 1 ? new AMap.Size(26, 38) : new AMap.Size(22, 33),
          // 图标的取图地址
          image: arr[i].image,
          // 图标所用图片大小
          imageSize: arr[i].type === 1 ? new AMap.Size(26, 38) : new AMap.Size(22, 33),
          zIndex: arr[i].type === 1 ? 100 : 99
        }),
        anchor: 'center', //设置锚点
        offset: new AMap.Pixel(0, 0) //设置偏移量
      })
      markers.push(marker)
    }
    console.log(4444)
    console.log(markers)
    map.add(markers)
    // map.setFitView();
    resolve(markers)
  })
}

export { initAMap, setMarker, multipleMarkers }