// 接口请求拦截白名单
const isWhiteListInit = (url) => {
  const apiUrlList = new Set([
    'v5/sms/send', // 发送验证码
    'v5/sms/sendVoiceCode', // 获取语音验证码
    'v5/sms/captcha', // 获取图形验证码
    'v5/sms/captchaValidate', // 图形验证码校验
    'v6/document/loginByWeChatBindPhone', // 绑定手机号
    'v3/document/logout', // 退出登录
    'v4/document/survey/SubmitSurveyForStore', // 提交门店入驻调查问卷信息
    'v3/document/user/unbanchar', // 禁用申诉
    'v3/document/fav/addFav', // 添加收藏
    'v3/document/fav/cancelFav', // 取消收藏
    'v5/document/technician/directAppointment', // 技师列表直接预约获取套餐信息
    'v3/document/modifyUser', // 修改用户资料
    'v3/document/changeBindPhone', // 更换手机号
    'v5/document/deleteUser', // 注销账户
    'v3/document/CleanBindWeChat', // 解除微信绑定
    'v3/document/fav/setOnlineRemind', // 关注技师上线，提醒我
    'v3/document/point/SignIn', // 积分签到
    'v3/document/point/ExchangePoint', // 积分兑换
    'v6/document/point/LuckyDraw', // 幸运大转盘
    'v6/document/survey/submit/userSurveyBack', // 提交回归问卷调查
    'v5/comment/show/LikeComment', // 点赞评价
    'v3/custom/GetImSig', // im登录获取用户sig
    'v3/address/CreateOrUpdateUserAddress', // 新增服务地址
    'v3/address/DeleteAddress', // 删除服务地址
    'v5/app/popup/saveUserPopup', // 保存用户弹框公告,后面不需要展示
    'v4/recruit/CityPartnerApply', // 城市合伙人申请
    'v4/recruit/CreateTechnicianApply', // 技师申请
    'v5/unlike/addOrRemove', // 不喜欢该技师
    'v5/order/modify/CustomerIsReadModifyOrder', // 修改订单弹框已读不再显示
    'v3/order/getCityPartnerPhone', // 获取商户联系人
    'v3/order/cancelOrder', // 取消订单
    'v3/order/deleteOrder', // 删除订单
    'v3/order/EndService', // 结束服务
    'v5/order/comment/addOrderComment', // 新增订单评论
    'v5/orderRefund/Apply', // 申请退款
    'v3/order/cancelRefoundsApply', // 取消申请退款
    'v4/order/customin', // 申请客服介入
    'v3/order/cancelCustomin', // 取消客服介入
    'v6/order/create/CreateOrder', // 确认下单
    'v3/order/speedUpOrder', // 催单
    'v5/order/modify/ModifyOrder', // 修改订单
    'v5/order/modify/RevokeModifyOrder', // 用户撤销订单修改请求
    'v5/order/comment/delOrderComment', // 删除评价
    'v5/order/create/CreateStoreOrder', // 创建门店订单
    'v3/feedback/CreateOrUpdateInformAgainstUser', // 新增举报
    'v3/feedback/againstsupplement', // 举报补充说明
    'v3/feedback/againstcommitagain', // 再次处理举报
    'v3/feedback/againstclose', // 关闭举报问题
    'v3/feedback/cancelInformAgainstUserById', // 取消举报
    'v3/feedback/addFeedback', // 新增反馈记录
    'v3/feedback/deleteUserQuestionList', // 取消反馈
    'v3/feedback/questionssupplement', // 反馈补充说明
    'v3/feedback/questionscommitagain', // 再次处理反馈
    'v3/feedback/questionsclose', // 反馈处理完成/关闭
    'v3/message/deleteAllMessages', // 一键删除所有系统信息
    'pay/control/queryPaymentMode', // 斗拱支付获取支付方式
    'pay/control/pay', // 支付产品订单
    'pay/control/addclock/pay', // 支付加钟
    'pay/control/userRewardOrder/pay', // 支付打赏
    'pay/control/userOpenVip/pay', // 开通会员/续费
    'pay/control/payshareorder/paymode', // 获取代付支付方式
    'pay/control/payshareorder/pay', // 获取代付订单详情
    'v3/communicate/handleApply', // 同意/不同意沟通申请
    'v3/wallet/ticket/userget', // 技师定制券
    'v6/coupons/activity/receive', // 领取优惠券
    'v6/coupons/activity/isShowCouponsActivity', // 是否展示优惠券
    'v6/coupons/mis/exchangePasswordCoupon', // 兑换优惠券
    'v3/AreaVote/vote', // 未开通城市投票
    'v5/userShareRecord/userShare', // 每日分享成功之后回调
    'v3/wx/public/BindWxPublicByCode', // 绑定微信
    'v5/show/like', // 点赞/取消作品点赞
    'v5/show/comment/like', // 点赞/取消作品评论点赞
    'v5/show/comment/submit', // 发送作品评论
    'v5/show/comment/del', // 删除作品评论
    'v5/show/comment/against', // 举报作品评论
    'v5/show/discover/userPetition', // 用户请愿
    'v5/growthCenter/receiveCoupon', // 领取优惠券
    'v5/growthCenter/receiveVip', // 领取会员卡
    'v5/growthCenter/setGrowthLevelRead', // 成长值：会员等级弹框提示
    'v3/setting/isShowServiceMeCount', // 展示是否服务过我
  ])
  if (apiUrlList.has(url)) {
    return true
  }
}

export { isWhiteListInit }
