// 配置文件
import { currentDev, isTestEnv } from './env'
// currentDev: 1：正式生产环境 // 2：内部测试环境 //3：预发布环境
const urlLink = {
  1: 'https://i.wangyuedaojia.com', //正式
  2: 'https://itest.wangyuedaojia.com', //内测
  // 2: 'http://192.168.17.51:8000',
  3: 'https://ipre.wangyuedaojia.com', //预发布
}
const tsApiUrlLink = {
  1: 'https://iwy.wangyuedaojia.com', //正式
  2: location.hostname.match(/[a-zA-Z]+/) ? 'https://iwytest.wangyuedaojia.com' : location.origin, //内测
  3: 'https://iwypre.wangyuedaojia.com', //预发布
}
const htmlLink = {
  1: 'https://wyh5.wangyuedaojia.com',
  2: 'https://wyh5test.wangyuedaojia.com',
}

const wyh5AppLink = {
  1: 'https://h5.wangyuedaojia.com/#', //生产
  2: `${import.meta.env.DEV ? "https://localhost:4430/#" : 'https://h5test.wangyuedaojia.com/#'}`, //测试
  3: 'https://h5.wangyuedaojia.com/pre/#', //预发布
}

const hdh5AppLink = {
  1: 'https://h5.wangyuedaojia.com/handi.html#', //生产
  2: `${import.meta.env.DEV ? "https://localhost:4430/handi.html#" : 'https://h5test.wangyuedaojia.com/handi.html#'}`, //测试
  3: 'https://h5.wangyuedaojia.com/pre/handi.html#', //预发布
}

const host = htmlLink[currentDev] //旧的wyh5访问地址
const wyh5AppHost = wyh5AppLink[currentDev] //新的wyh5app访问地址
const hdh5AppHost = hdh5AppLink[currentDev] //新的wyh5app访问地址
const baseUrl = tsApiUrlLink[currentDev] + '/api/' //API HOST
const tsApiBaseUrl = tsApiUrlLink[currentDev] //API HOST
const regTel = /^1[3456789]\d{9}$/ //手机号校验
const regCode = /(\d){6}$/ //验证码校验
const regPassword = /^[0-9A-Za-z_~!@#$%^&*]{8,20}$/ //密码校验
const wxJssdkUrl = 'https://res2.wx.qq.com/open/js/jweixin-1.6.0.js'
const appidCodeArr = [{
  code: 'WANG_YUE',
  name: '往约',
  appid: 'wxd547642416441d89',
  appCode: 'WY'
}, {
  code: 'FEI_ZHANG',
  name: '飞掌',
  appid: 'wx1c5d0ad4a1016890',
  appCode: 'FZ'
}, {
  code: 'YE_SHE_HUI',
  name: '夜奢汇精油SPA',
  appid: 'wx99cbbe079f30543a',
  appCode: 'YSH'
}, {
  code: 'HAN_DI',
  name: '汗滴到家',
  appid: 'wx4bd032270709cb7f',
  appCode: 'HD'
}, {
  code: 'WANG_YUE_TECH',
  name: '往约技师端',
  appid: 'wxdeda864a4df30520',
  appCode: 'WYTECH'
}]

const devSDKAppID = 1600043074 //测试环境SDKAppID 老的：1400758194  新的：1600026831  新：1600029608  新：1600031057 新：1600043074
const prdSDKAppID = 1400128918 //正式环境

const imSdkAppID = (isTestEnv ? devSDKAppID : prdSDKAppID); // Your SDKAppID

export {
  wxJssdkUrl,
  host,
  wyh5AppHost,
  hdh5AppHost,
  baseUrl,
  tsApiBaseUrl,
  urlLink,
  regTel, //手机号校验
  regCode, //验证码校验
  regPassword, //密码校验
  appidCodeArr,
  imSdkAppID
}
